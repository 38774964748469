import React, { useMemo, useRef, useState, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useClickAway } from 'react-use';
import cn from 'classnames';
import { setCookie } from 'cookies-next';

import stylesHeader from '../Header.module.scss';
import styles from './LanguageToggle.module.scss';

const options = [
  {
    label: 'Ru',
    value: 'ru',
    name: 'Русский',
  },
  {
    label: 'En',
    value: 'en',
    name: 'English',
  },
];

interface LanguageProps {
  isMobile: boolean;
  theme?: 'white' | 'black';
}

const LanguageToggle: React.FC<LanguageProps> = ({
  isMobile,
  theme = 'white',
}) => {
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };
  const rootRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const selectedLanguage = useMemo<string>(() => {
    const nextOption = options.find(item => item.value === currentLanguage);
    return nextOption.label;
  }, [currentLanguage]);

  const handleSelectLanguageChange = useCallback(
    ({ value }) => {
      const nextQuery = Object.keys(router.query).reduce((acc, q) => {
        if (router.query[q]) {
          acc[q] = router.query[q];
        }
        return acc;
      }, {});

      setCookie('NEXT_LOCALE', value, { maxAge: 60 * 60 * 24 * 365 });

      router.push(
        {
          pathname: router.asPath,
          query: {
            ...nextQuery,
          },
        },
        router.pathname,
        { locale: value }
      );
    },
    [router]
  );

  const handleSelectedClick = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const handleOptionClick = useCallback(
    lang => {
      if (currentLanguage !== lang) {
        handleSelectLanguageChange(lang);
        document.querySelector('body').classList.remove('lock');
      }
      setIsOpen(false);
    },
    [currentLanguage, handleSelectLanguageChange]
  );

  useClickAway(rootRef, () => {
    if (isOpen) {
      setIsOpen(false);
    }
  });

  return (
    <div
      ref={rootRef}
      className={cn(stylesHeader.header__menuIconsLink, styles.selectBox, {
        [styles.selectBoxOpen]: isOpen,
        [styles.desktopVersion]: !isMobile,
      })}
    >
      <div className={stylesHeader.header__menuIconsLanguage}>
        {!isMobile && (
          <div className={styles.selectedOption} onClick={handleSelectedClick}>
            {selectedLanguage}
            <div
              className={cn(styles.arrow, {
                [styles.white]: theme === 'black',
              })}
            />
          </div>
        )}
        <div className={styles.optionsGrid}>
          <ul className={styles.optionsList}>
            {options.map(({ label, value, name }) => (
              <li
                key={value}
                className={cn(styles.option, {
                  [styles.active]: selectedLanguage == label,
                })}
                onClick={(): void => handleOptionClick({ label, value })}
              >
                {isMobile ? label : name}
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LanguageToggle;
