import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setCookie } from 'cookies-next';
import { authCookieName } from 'helpers/userAuth';
import { setAuthorization } from 'stores/userStore';
import { useRouter } from 'next/router';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import ModalAuthActivate from 'components/blocks/Modal/ModalAuth/ModalAuthActivate/ModalAuthActivate';
import ModalAuthConfirm from 'components/blocks/Modal/ModalAuth/ModalAuthConfirm/ModalAuthConfirm';
import ModalAuthSuccess from 'components/blocks/Modal/ModalAuth/ModalAuthSuccess/ModalAuthSuccess';
import ModalAuthLogin from 'components/blocks/Modal/ModalAuth/ModalAuthLogin/ModalAuthLogin';
import Notification from 'components/blocks/Notification';

const ModalAuth: React.FC = () => {
  const { t } = useTranslation(['modal', 'feedback']);
  const dispatch = useDispatch();
  const router = useRouter();
  const { setModalType, modalAuthAfterSuccess } = useContext(AppContext);

  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [signUpResendData, setSignUpResendData] = useState({});
  const [showModalNotification, setShowModalNotification] = useState(false);

  const modalNotificationChange = (modalState: boolean) => {
    setShowModalNotification(modalState);
  };

  const login = token => {
    setCookie(authCookieName, token, { maxAge: 60 * 60 * 24 * 30 });
    dispatch(setAuthorization(true));
    setShowModalNotification(true);
  };

  const successAuth = data => {
    login(data.auth_token);

    if (data.locale !== router.locale) {
      setCookie('NEXT_LOCALE', data.locale);
      router.push(
        router.pathname,
        {},
        {
          locale: data.locale,
        }
      );
    }

    if (modalAuthAfterSuccess === MODAL.auth.activate) {
      setModalType(MODAL.auth.activate);
      return;
    }

    if (modalAuthAfterSuccess === 'showOrder') {
      router.push('/order');
    }

    setModalType('');
  };

  return (
    <>
      <ModalAuthLogin
        successSignIn={successAuth}
        setCurrentUserEmail={setCurrentUserEmail}
        setSignUpResendData={setSignUpResendData}
      />
      <ModalAuthConfirm
        currentUserEmail={currentUserEmail}
        signUpResendData={signUpResendData}
        successConfirm={successAuth}
      />
      <ModalAuthActivate />
      <ModalAuthSuccess />
      <Notification
        text={t('modal.notification_auth')}
        isOpen={showModalNotification}
        changeModalState={modalNotificationChange}
      />
    </>
  );
};

export default ModalAuth;
