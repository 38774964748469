import React, { useState } from 'react';
import cn from 'classnames';

import { ErrorMessage, Field } from 'formik';
import InputMask from 'react-input-mask';

import styles from './FormInput.module.scss';

interface FormInputProps {
  type: string;
  placeholder: string;
  name?: string;
  error?: boolean;
  addClasses?: string[];
  addContainerClasses?: string[];
  label?: string;
  onInput?: any;
  onBlur?: any;
  inputMode?: string;
  innerRef?: any;
  mask?: string;
  onChangeWithMask?: any;
  disabled?: boolean;
  value?: any;
}

const FormInput: React.FC<FormInputProps> = ({
  type,
  name,
  placeholder,
  error,
  addClasses = [],
  addContainerClasses = [],
  label,
  onInput,
  onBlur,
  inputMode,
  innerRef,
  mask,
  onChangeWithMask,
  disabled,
  value,
}) => {
  const classes = cn(styles.formInput__fieldInput, ...addClasses, {
    [styles.error]: error,
    [styles.success]: !error && error !== undefined,
    [styles.password]: type === 'password',
  });

  const [showPassword, setShowPassword] = useState(false);

  return (
    <div className={cn(styles.formInput__field, ...addContainerClasses)}>
      {label && <p className={styles.formInput__fieldLabel}>{label}</p>}
      {mask ? (
        <InputMask
          mask={mask}
          onInput={onInput}
          onChange={onChangeWithMask}
          onBlur={onBlur}
        >
          {() => (
            <Field
              type={type === 'password' && showPassword ? 'text' : type}
              name={name}
              placeholder={placeholder}
              className={classes}
              inputMode={inputMode}
              innerRef={innerRef}
            />
          )}
        </InputMask>
      ) : (
        <Field
          type={type === 'password' && showPassword ? 'text' : type}
          name={name}
          placeholder={placeholder}
          className={classes}
          onInput={onInput}
          onBlur={onBlur}
          inputMode={inputMode}
          innerRef={innerRef}
          disabled={disabled}
          {...(value ? { value: value } : {})}
        />
      )}
      {type === 'password' && (
        <div
          onClick={() => setShowPassword(!showPassword)}
          className={cn(styles.formInput__showPassword, {
            [styles.open]: showPassword,
          })}
        />
      )}
      {error && (
        <ErrorMessage
          name={name}
          component='p'
          className={styles.formInput__errorLabel}
        />
      )}
    </div>
  );
};

export default FormInput;
