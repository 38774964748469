import { genericAnchors, mainPageAnchors } from './anchors';
import { links } from './constants/links';

export const menuLinksDefault = [
  {
    id: 'howWorks',
    link: `/${links.guide}`,
  },
  {
    id: 'prices',
    link: `/#${mainPageAnchors.tariffs}`,
  },
  {
    id: 'news',
    link: `/${links.news}`,
  },
  {
    id: 'organizers',
    link: `/${links.organizers}`,
  },
  {
    id: 'var',
    link: `/${links.var}`,
  },
  {
    id: 'shop',
    link: `/${links.shop}`,
  },
];

export const menuLinksMainPage = [
  {
    id: 'howWorks',
    link: `/${links.guide}`,
  },
  {
    id: 'prices',
    link: `#${mainPageAnchors.tariffs}`,
  },
  {
    id: 'news',
    link: `/${links.news}`,
  },
  {
    id: 'organizers',
    link: `/${links.organizers}`,
  },
  {
    id: 'var',
    link: `/${links.var}`,
  },
  {
    id: 'shop',
    link: `/${links.shop}`,
  },
];

export const footerMenuLinksDefault = {
  rightMenu: [
    {
      id: 'howWorks',
      link: `/${links.guide}`,
    },
    {
      id: 'advantages',
      link: `#${genericAnchors.advantages}`,
    },
    {
      id: 'products',
      link: `/${links.shop}`,
    },
    {
      id: 'organizers',
      link: `/${links.organizers}`,
    },
    {
      id: 'reviews',
      link: `/${links.reviews}`,
    },
    {
      id: 'faq',
      link: `/${links.faq}`,
    },
    {
      id: 'tournaments',
      link: `/${links.news}`,
    },
  ],
  centerMenu: [
    {
      id: 'partners',
      link: `/#${mainPageAnchors.partners}`,
    },
    {
      id: 'media',
      link: `/#${mainPageAnchors.media}`,
    },
    {
      id: 'instruction',
      link: `/${links.guide}`,
    },
  ],
};
