import React, { useContext, useEffect, useMemo, useState } from 'react';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { authCookieName } from 'helpers/userAuth';
import 'lazysizes/plugins/attrchange/ls.attrchange';
import { cookieAgreementName } from 'helpers/cookieAgreement';
import { getCookie } from 'cookies-next/lib';
import { setCookie } from 'cookies-next';
import cn from 'classnames';
import { RootState } from 'stores/store';
import { IUserStore, setPageScrollTo, setPrevPage } from 'stores/userStore';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Footer from 'components/blocks/Footer';
import Header from 'components/blocks/Header';
import GoogleTag from 'public/metrics/GoogleTag';
import VkPixel from 'public/metrics/VkPixel';
import YandexMetrika from 'public/metrics/YandexMetrika';
import Jivosite from 'public/metrics/Jivosite';
import ModalSuccess from 'components/blocks/Modal/ModalSuccess';
import ModalAuth from 'components/blocks/Modal/ModalAuth';
import ModalMessage from 'components/blocks/Modal/ModalMessage';
import Cookie from 'components/blocks/Cookie';
import ModalSelection from 'components/blocks/Modal/ModalSelection';

interface MainLayoutPageProps {
  children?: any;
  title?: string;
  isGray?: boolean;
}

const Layout: React.FC<MainLayoutPageProps> = props => {
  const { children } = props;
  const router = useRouter();
  const { t } = useTranslation('modal');
  const dispatch = useDispatch();
  const userStored = useSelector<RootState, IUserStore>(state => state.user);

  const { setModalType, setModalMessageData, setModalAuthAfterSuccess } =
    useContext(AppContext);

  const [showCookieModal, setShowCookieModal] = useState(false);

  const pageScrollToStored = userStored.pageScrollTo;
  const lang = ['ru', 'fr'];

  useEffect(() => {
    if (router.query.activate) {
      setModalAuthAfterSuccess('');
      setModalType(
        userStored.isAuthorized ? MODAL.auth.activate : MODAL.auth.signUp
      );
    }
  }, [router.query.activate, userStored]);

  useEffect(() => {
    if (router.query.payment_failure) {
      setModalMessageData({
        title: t('modal.payment_query.failure.title'),
        subTitle: t('modal.payment_query.failure.subTitle'),
        buttonText: t('modal.payment_query.buttonText'),
      });
      setModalType(MODAL.message);
    }
  }, [router.query.payment_failure]);

  useEffect(() => {
    if (router.query.payment_cart_success) {
      const userMail = router.query.payment_license_email
        ? router.query.payment_license_email
        : '';

      setModalMessageData({
        title: t('modal.payment_query.success.title'),
        subTitle: `${t('modal.payment_query.success.subTitle_1')} <br>${
          t('modal.payment_query.success.subTitle_2') + userMail
        }`,
        buttonText: t('modal.payment_query.buttonText'),
      });
      setModalType(MODAL.success);
    }
  }, [router.query.payment_cart_success, router.query.payment_license_email]);

  useEffect(() => {
    setShowCookieModal(!getCookie(cookieAgreementName));
  }, [getCookie(cookieAgreementName)]);

  useEffect(() => {
    if (router.query.auth_token) {
      setCookie(authCookieName, router.query.auth_token, {
        maxAge: 60 * 60 * 24 * 30,
      });
    }
  }, [router.query.auth_token]);

  useEffect(() => {
    const handleRouteChange = nextUrl => {
      const scrollTop =
        document?.body?.scrollTop || document?.documentElement?.scrollTop || 0;
      const path = router.pathname.split(/\[|\]/);
      const pathForSave = path
        .map(item => {
          if (router.query[item]) {
            return router.query[item];
          }

          return item;
        })
        .join('');

      if (nextUrl === pathForSave) {
        return;
      }

      dispatch(
        setPrevPage({
          url: pathForSave,
          position: scrollTop,
        })
      );
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router]);

  useEffect(() => {
    const scroll = async () => {
      const scrollTo = pageScrollToStored;
      document.documentElement.scrollTo(0, scrollTo);
      dispatch(setPageScrollTo(0));
    };

    if (pageScrollToStored > 0) {
      scroll();
    }
  }, []);

  const ogUrl = useMemo<string>(() => {
    if (process.browser) {
      return window.location.href;
    }

    return router.asPath;
  }, []);

  const ogImage = useMemo(() => {
    return `/og/open_graph_${router.locale}.jpg`;
  }, [router.locale]);

  return (
    <>
      <Head>
        <link
          rel='shortcut icon'
          href='/images/favicon.ico'
          type='image/x-icon'
        />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/images/apple-touch-icon.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/images/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/images/favicon-16x16.png'
        />
        <link rel='manifest' href='/site.webmanifest' />
        <link
          rel='mask-icon'
          href='/images/safari-pinned-tab.svg'
          color='#000000'
        />
        <meta name='msapplication-TileColor' content='#2b5797' />
        <meta name='theme-color' content='#ffffff' />

        <meta content='website' property='og:type' />
        <meta content={ogImage} property='og:image' />
        <meta content={ogUrl} property='og:url' />

        <meta name='yandex-verification' content='31051bb6654d8056' />

        {lang.map(value => (
          <link
            key={value}
            rel='alternate'
            hrefLang={value}
            href={`${process.env.NEXT_PUBLIC_API_HOST}/${
              value + router.pathname
            }`}
          />
        ))}
        <link
          rel='alternate'
          hrefLang='x-default'
          href={`${process.env.NEXT_PUBLIC_API_HOST + router.pathname}`}
        />

        {process.env.NODE_ENV === 'production' && (
          <>
            <GoogleTag />
            <YandexMetrika />
            <VkPixel />
            <Jivosite />
          </>
        )}
      </Head>
      <div className='wrapper'>
        <Header />
        <div
          className={cn('wrapper-content', {
            ['wrapper-content--gray']: props.isGray,
          })}
        >
          {children}
        </div>
        <Footer />
        <ModalAuth />
        <ModalMessage />
        <ModalSuccess />
        <ModalSelection />
        {showCookieModal && <Cookie />}
      </div>
    </>
  );
};

export default Layout;
