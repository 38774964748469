import React, { useContext } from 'react';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Button from 'components/blocks/Button';
import Modal from 'components/blocks/Modal';
import { Trans } from 'next-i18next';

import styles from './ModalMessage.module.scss';

const ModalMessage: React.FC = () => {
  const { modalType, setModalType, modalMessageData } = useContext(AppContext);

  return (
    <>
      <Modal
        isOpen={modalType === MODAL.message}
        additionalClasses={[styles.modalMessage__content]}
      >
        <h2 className={styles.modalMessage__title}>
          <Trans>{modalMessageData?.title}</Trans>
        </h2>
        <p className={styles.modalMessage__subtitle}>
          <Trans>{modalMessageData?.subTitle}</Trans>
        </p>
        <Button
          placeholder={modalMessageData?.buttonText}
          addStyles={[styles.modalMessage__button]}
          onClick={() => setModalType('')}
        />
        <div className='closeButton' onClick={() => setModalType('')} />
      </Modal>
    </>
  );
};

export default ModalMessage;
