import React, { useContext } from 'react';
import { useTranslation } from 'next-i18next';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Button from 'components/blocks/Button';
import Modal from 'components/blocks/Modal';

import styles from '../ModalCountry.module.scss';
import buttonStyles from 'components/blocks/Button/Button.module.scss';

interface IModalCountryMain {
  currentCountry: string;
}

const ModalCountryMain: React.FC<IModalCountryMain> = ({ currentCountry }) => {
  const { t } = useTranslation('modal');
  const { modalType, setModalType } = useContext(AppContext);

  return (
    <Modal
      isOpen={modalType === MODAL.country.main}
      additionalClasses={[
        styles.modalCountry__content,
        styles.modalCountry__contentMin,
      ]}
    >
      <div className={styles.modalCountry__title}>
        {t('modal.countries.country')} {t(currentCountry)}?
      </div>
      <div className={styles.modalCountry__buttons}>
        <Button
          placeholder={t('modal.countries.no')}
          addStyles={[
            styles.modalCountry__button,
            buttonStyles.button__invisible,
          ]}
          onClick={() => setModalType(MODAL.country.select)}
        />
        <Button
          placeholder={t('modal.countries.yes')}
          addStyles={[styles.modalCountry__button]}
          onClick={() => setModalType('')}
        />
      </div>
      <div className='closeButton' onClick={() => setModalType('')} />
    </Modal>
  );
};

export default ModalCountryMain;
