import React, { useContext } from 'react';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Button from 'components/blocks/Button';
import Modal from 'components/blocks/Modal';
import { Trans } from 'next-i18next';

import styles from './ModalSuccess.module.scss';
import buttonStyles from 'components/blocks/Button/Button.module.scss';

const ModalSuccess: React.FC = () => {
  const { modalType, setModalType, modalMessageData } = useContext(AppContext);

  return (
    <Modal
      isOpen={modalType === MODAL.success}
      additionalClasses={[styles.modalSuccess__content]}
    >
      <h2 className={styles.modalSuccess__title}>
        <Trans>{modalMessageData?.title}</Trans>
      </h2>
      <p className={styles.modalSuccess__subtitle}>
        <Trans>{modalMessageData?.subTitle}</Trans>
      </p>
      <Button
        placeholder={modalMessageData?.buttonText}
        addStyles={[styles.modalSuccess__button, buttonStyles.button__mobH48]}
        onClick={() => setModalType('')}
      />
      <div className='closeButton' onClick={() => setModalType('')} />
    </Modal>
  );
};

export default ModalSuccess;
