import React, { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { exitSubmit } from 'services/authService';
import { authCookieName } from 'helpers/userAuth';
import { deleteCookie } from 'cookies-next/lib';
import { setAuthorization, setEmailData } from 'stores/userStore';
import { useDispatch } from 'react-redux';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Button from 'components/blocks/Button';
import Modal from 'components/blocks/Modal';

import styles from './ModalExit.module.scss';
import modalStyles from 'components/blocks/Modal/Modal.module.scss';
import buttonStyles from 'components/blocks/Button/Button.module.scss';

const ModalExit: React.FC = () => {
  const { t } = useTranslation('modal');
  const dispatch = useDispatch();
  const { modalType, setModalType } = useContext(AppContext);

  const exitHandler = useCallback(async () => {
    exitSubmit({
      successCallback: () => {
        deleteCookie(authCookieName);
        setModalType('');
        dispatch(setAuthorization(false));
        dispatch(setEmailData(''));
      },
    });
  }, []);

  return (
    <>
      <Modal
        isOpen={modalType === MODAL.exit}
        additionalClasses={[modalStyles.modal__contentCommon, styles.modalExit]}
      >
        <div className={modalStyles.modal__title}>{t('modal.exit.title')}</div>
        <div className={styles.modalExit__text}>{t('modal.exit.text')}</div>
        <div className={styles.modalExit__buttonContainer}>
          <Button
            placeholder={t('modal.exit.yes')}
            addStyles={[
              styles.modalExit__button,
              buttonStyles.button__invisible,
            ]}
            onClick={exitHandler}
          />
          <Button
            placeholder={t('modal.exit.no')}
            addStyles={[styles.modalExit__button]}
            onClick={() => setModalType('')}
          />
        </div>
        <div className='closeButton' onClick={() => setModalType('')} />
      </Modal>
    </>
  );
};

export default ModalExit;
