import axios from 'axios';
//import { geoCountries } from 'helpers/geoCountries';

export const getGeoInfo = async () => {
  try {
    // пока оставим только ip
    /*if (Intl?.DateTimeFormat()?.resolvedOptions()?.timeZone) {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return geoCountries[userTimeZone];
    }*/

    const response = await axios
      .get('https://ipapi.co/json/')
      .then(response => {
        if (typeof response?.data?.country_code === 'string') {
          return response.data.country_code;
        }

        return false;
      });

    return response;
  } catch (e) {
    console.error(e?.response);
    return false;
  }
};
