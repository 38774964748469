import React, { useContext, useState } from 'react';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';
import { setCookie, hasCookie } from 'cookies-next';
import { countryCookieName, countriesCode } from 'helpers/geoCountries';
import { useDispatch } from 'react-redux';
import { setGeoData } from 'stores/userStore';
import { getCookie } from 'cookies-next/lib';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Button from 'components/blocks/Button';
import Modal from 'components/blocks/Modal';
import Select from 'react-select';

import styles from '../ModalCountry.module.scss';

interface IModalCountrySelect {
  setCurrentCountry: (string) => void;
}

const ModalCountrySelect: React.FC<IModalCountrySelect> = ({
  setCurrentCountry,
}) => {
  const { t } = useTranslation('modal');
  const dispatch = useDispatch();

  const { modalType, setModalType } = useContext(AppContext);

  const defaultCountryId = hasCookie(countryCookieName)
    ? getCookie(countryCookieName)
    : t('modal.countries.defaultCountryId');

  const [selectedOption, setSelectedOption] = useState({
    value: defaultCountryId,
    label: t(`geoCountries.${defaultCountryId}`),
  });

  const countriesOptions = countriesCode.map((item: string) => {
    return {
      value: item,
      label: t(`geoCountries.${item}`),
    };
  });

  const changeCountry = async countryId => {
    dispatch(setGeoData(countryId));
    setCurrentCountry(`geoCountries.${countryId}`);
    setCookie(countryCookieName, countryId);
  };

  const changeCountryButton = () => {
    changeCountry(selectedOption.value);
    setModalType('');
  };

  return (
    <Modal
      isOpen={modalType === MODAL.country.select}
      additionalClasses={[
        styles.modalCountry__content,
        styles.modalCountry__contentSelect,
      ]}
    >
      <div className={styles.modalCountry__title}>
        {t('modal.countries.select')}
      </div>
      <div className={styles.modalCountry__subtitle}>
        {t('modal.countries.location')}
      </div>
      <Select
        defaultValue={selectedOption}
        onChange={setSelectedOption}
        options={countriesOptions}
        className={styles.modalCountry__select}
        classNames={{
          control: state =>
            state.menuIsOpen
              ? cn(styles.modalCountry__selectControl, styles.isFocused)
              : styles.modalCountry__selectControl,
          input: () => styles.modalCountry__selectInput,
          indicatorsContainer: () => styles.modalCountry__indicatorsContainer,
          indicatorSeparator: () => styles.modalCountry__indicatorSeparator,
          valueContainer: () => styles.modalCountry__valueContainer,
          menu: () => styles.modalCountry__menu,
          option: () => styles.modalCountry__option,
        }}
        theme={theme => ({
          ...theme,
          colors: {
            ...theme.colors,
            primary: '#34c759',
            primary75: '#EBFAEF',
            primary50: '#EBFAEF',
            primary25: '#EBFAEF',
          },
        })}
        placeholder={t('modal.countries.location')}
        noOptionsMessage={() => t('modal.countries.noCountry')}
      />
      <Button
        placeholder={t('modal.countries.changeCountry')}
        addStyles={[styles.modalCountry__buttonSelect]}
        onClick={() => changeCountryButton()}
      />
      <div className='closeButton' onClick={() => setModalType('')} />
    </Modal>
  );
};

export default ModalCountrySelect;
