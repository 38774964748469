import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormValidate, handleSetErrorText } from 'helpers/hooks/form';
import { useSelector } from 'react-redux';
import { submitResendCode, submitConfirm } from 'services/authService';
import { useRouter } from 'next/router';
import { IUserStore } from 'stores/userStore';
import { RootState } from 'stores/store';
import { handleChangeCode } from 'helpers/hooks/changeCode';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Button from 'components/blocks/Button';
import Modal from 'components/blocks/Modal';
import { Form, Formik } from 'formik';
import FormInput from 'components/blocks/FormInput';

import styles from 'components/blocks/Modal/ModalAuth/ModalAuth.module.scss';
import modalStyles from 'components/blocks/Modal/Modal.module.scss';
import formStyles from 'components/blocks/Field/Field.module.scss';
import inputStyles from 'components/blocks/FormInput/FormInput.module.scss';

interface IModalAuthConfirm {
  currentUserEmail: string;
  signUpResendData: {};
  successConfirm: (value) => void;
}

const ModalAuthConfirm: React.FC<IModalAuthConfirm> = ({
  signUpResendData,
  currentUserEmail,
  successConfirm,
}) => {
  const { t } = useTranslation(['modal', 'feedback']);
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };

  const geoStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = geoStored.geoData;
  const { modalType, setModalType, modalAuthAfterSuccess } =
    useContext(AppContext);

  const confirmCode = useRef(null);

  const [timer, setTimer] = useState(60);

  const [codeError, setCodeError] = useState();

  const [responseFieldError, setResponseFieldError] = useState('');
  const [responseError, setResponseError] = useState('');
  const [isWaiting, setWaiting] = useState(false);

  const validateConfirm = useFormValidate({
    codeError: setCodeError,
  });

  const handleValidate = values => {
    if (responseFieldError) {
      setResponseFieldError('');
    }

    if (responseError) {
      setResponseError('');
    }

    return validateConfirm(values);
  };

  const submitErrorField = data => {
    setResponseFieldError(handleSetErrorText(data, t));
  };

  const resendCode = useCallback(async () => {
    submitResendCode(signUpResendData, {
      successCallback: () => {
        setTimer(60);
      },
      errorFieldCallback: submitErrorField,
    });
  }, [signUpResendData]);

  const handleSubmitConfirm = useCallback(
    async (data, actions) => {
      submitConfirm(data, actions, {
        language: currentLanguage,
        geo: geoDataStored,
        email: currentUserEmail,
        successCallback: successConfirm,
        errorFieldCallback: submitErrorField,
        beforeSubmit: () => {
          setWaiting(true);
        },
        afterSubmit: () => {
          setWaiting(false);
        },
      });
    },
    [currentUserEmail, modalAuthAfterSuccess, geoDataStored]
  );

  useEffect(() => {
    if (modalType !== MODAL.auth.confirm) {
      return;
    }

    if (timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [timer, modalType]);

  useEffect(() => {
    setResponseFieldError('');
    setResponseError('');
  }, [modalAuthAfterSuccess]);

  return (
    <Modal
      isOpen={modalType === MODAL.auth.confirm}
      additionalClasses={[
        modalStyles.modal__contentCommon,
        styles.modalAuth__confirm,
      ]}
      isWaiting={isWaiting}
    >
      <h3
        className={cn(
          'title__secondary',
          styles.modalAuth__title,
          styles.modalAuth__titleConfirm
        )}
      >
        {t('modal.confirm.title')}
      </h3>
      <div className={styles.modalAuth__codeAddress}>
        {t('modal.confirm.codeText')}
        <span className='text-green'>{currentUserEmail}</span>
      </div>
      <Formik
        initialValues={{
          code: '',
        }}
        validate={handleValidate}
        onSubmit={handleSubmitConfirm}
      >
        {({ isSubmitting, dirty, isValid, setFieldTouched }) => (
          <Form>
            <FormInput
              type='text'
              name='code'
              placeholder='000000'
              error={codeError}
              addClasses={[formStyles.field__inputCode]}
              addContainerClasses={[formStyles.field__inputCodeContainer]}
              onInput={e => {
                handleChangeCode(e, 6, confirmCode);
              }}
              onBlur={() => setFieldTouched('code')}
              inputMode={'numeric'}
              innerRef={confirmCode}
            />
            {responseFieldError && (
              <div
                className={cn(
                  inputStyles.formInput__errorLabel,
                  inputStyles.formInput__errorLabelStatic
                )}
              >
                {responseFieldError}
              </div>
            )}
            <div className={modalStyles.modal__resend}>
              {timer === 0 && (
                <div className={cn('text-green', 'link')} onClick={resendCode}>
                  {t('modal.confirm.resendCodeLink')}
                </div>
              )}
              {timer > 0 && (
                <>
                  {t('modal.confirm.resendCode')}{' '}
                  <span className='text-green'>{timer}s</span>
                </>
              )}
            </div>
            <Button
              placeholder={t('modal.confirm.button')}
              disabled={isSubmitting || !(dirty && isValid)}
              type='submit'
              addStyles={[styles.modalAuth__submit]}
            />
          </Form>
        )}
      </Formik>
      <div className='closeButton' onClick={() => setModalType('')} />
    </Modal>
  );
};

export default ModalAuthConfirm;
