import React from 'react';

const LiveIcon: React.FC = () => {
  return (
    <svg
      width='25'
      height='24'
      viewBox='0 0 25 24'
      fill='none'
      role='img'
      focusable='false'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.5 19C2.5 18.4477 2.94772 18 3.5 18C4.05228 18 4.5 18.4477 4.5 19C4.5 19.5523 4.05228 20 3.5 20C2.94772 20 2.5 19.5523 2.5 19Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M6.5 20C6.5 17.7909 4.70914 16 2.5 16V14C5.81371 14 8.5 16.6863 8.5 20H6.5Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.5 20C10.5 15.5817 6.91828 12 2.5 12V10C8.02285 10 12.5 14.4771 12.5 20H10.5Z'
        fill='white'
      />
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M2.5 4H22.5V20H14.5V18H20.5V6H4.5V8H2.5V4Z'
        fill='white'
      />
    </svg>
  );
};

export default LiveIcon;
