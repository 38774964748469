import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

interface FormValidateItem {
  emailError?: any;
  nameError?: any;
  phoneError?: any;
  phoneMaskRuError?: any;
  codeError?: any;
  privacyCheckboxError?: any;
  passwordError?: any;
  licenseCodeError?: any;
  surnameError?: any;
  cityError?: any;
  streetError?: any;
  flatError?: any;
  dateError?: any;
  promocodeError?: any;
  houseError?: any;
  passwordConfirmationError?: any;
  organizationError?: any;
  noteError?: any;
}

export const useFormValidate = ({
  emailError,
  nameError,
  phoneError,
  phoneMaskRuError,
  privacyCheckboxError,
  passwordError,
  codeError,
  licenseCodeError,
  surnameError,
  cityError,
  streetError,
  flatError,
  dateError,
  promocodeError,
  houseError,
  organizationError,
  noteError,
  passwordConfirmationError,
}: FormValidateItem) => {
  const { t } = useTranslation(['feedback']);

  return useCallback(values => {
    interface error {
      name?: string;
      email?: string;
      phone?: string;
      phone_mask_ru?: string;
      code?: string;
      password?: string;
      data_processing?: string;
      surname?: string;
      city?: string;
      street?: string;
      flat?: string;
      date?: string;
      promocode?: string;
      house?: string;
      licenseCode?: string;
      organization?: string;
      note?: string;
      password_confirmation?: string;
    }

    const errors: error = {};

    const nameRegex = new RegExp(
      /^([^\d!"#\$%&\(\)\*\+,\\\.\/:;<=>\?@\[\]\^_`\|{}~№]||[ -]){1,}$/
    );

    const licenseCodeRegex = /^[A-Z0-9]{16}$/i;

    if (typeof emailError !== 'undefined') {
      emailError(false);

      if (!values.email?.trim()) {
        errors.email = t('feedback.emailField.emailEmptyText');

        emailError(true);
      } else if (
        !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
      ) {
        errors.email = t('feedback.emailField.emailErrorText');

        emailError(true);
      }
    }

    if (typeof nameError !== 'undefined') {
      nameError(false);

      if (!values.name?.trim()) {
        errors.name = t('feedback.nameField.nameEmptyText');

        nameError(true);
      } else if (values.name.length < 1 || values.name.length > 100) {
        errors.name = t('feedback.nameField.nameErrorText');

        nameError(true);
      } else if (!nameRegex.test(values.name)) {
        errors.name = t('feedback.nameField.nameRegexErrorText');

        nameError(true);
      }
    }

    if (typeof phoneError !== 'undefined') {
      phoneError(false);

      if (!values.phone?.toString().trim()) {
        errors.phone = t('feedback.phoneField.phoneEmptyText');

        phoneError(true);
      } else if (!/^([+]?[0-9\s\-\(\)]{5,25})*$/i.test(values.phone)) {
        errors.phone = t('feedback.phoneField.phoneErrorText');

        phoneError(true);
      }
    }

    if (typeof phoneMaskRuError !== 'undefined') {
      phoneMaskRuError(false);

      if (!values.phone_mask_ru?.toString().trim()) {
        errors.phone_mask_ru = t('feedback.phoneField.phoneEmptyText');

        phoneMaskRuError(true);
      } else if (
        !/^[+]?(7|8)[\s\-\(\)]{0,2}9[0-9\s\-\(\)]{9,14}$/i.test(
          values.phone_mask_ru
        )
      ) {
        errors.phone_mask_ru = t('feedback.phoneField.phoneErrorText');

        phoneMaskRuError(true);
      }
    }

    if (typeof privacyCheckboxError !== 'undefined') {
      privacyCheckboxError(false);

      if (!values.data_processing) {
        privacyCheckboxError(true);

        errors.data_processing = t('feedback.privacyCheckbox.privacyErrorText');
      }
    }

    if (typeof passwordError !== 'undefined') {
      passwordError(false);

      if (!values.password?.toString().trim()) {
        errors.password = t('feedback.passwordField.emptyText');

        passwordError(true);
      } else if (values.password.toString().length < 8) {
        errors.password = t('feedback.passwordField.errorText');

        passwordError(true);
      }
    }

    if (typeof passwordConfirmationError !== 'undefined') {
      passwordConfirmationError(false);

      if (!values.password_confirmation?.toString().trim()) {
        errors.password_confirmation = t('feedback.passwordField.emptyText');

        passwordConfirmationError(true);
      } else if (values.password_confirmation !== values.password) {
        errors.password_confirmation = t('feedback.passwordField.confirmError');

        passwordConfirmationError(true);
      }
    }

    if (typeof codeError !== 'undefined') {
      codeError(false);

      if (!values.code) {
        errors.code = t('feedback.codeField.emptyText');

        codeError(true);
      } else if (values.code.toString().length !== 6) {
        errors.code = t('feedback.codeField.errorText');

        codeError(true);
      }
    }

    if (typeof licenseCodeError !== 'undefined') {
      licenseCodeError(false);

      if (!values.licenseCode) {
        errors.licenseCode = t('feedback.codeField.emptyText');

        licenseCodeError(true);
      } else if (!licenseCodeRegex.test(values.licenseCode)) {
        errors.licenseCode = t('feedback.codeField.errorText');

        licenseCodeError(true);
      }
    }

    if (typeof surnameError !== 'undefined') {
      surnameError(false);

      if (!values.surname?.toString().trim()) {
        errors.surname = t('feedback.surnameField.emptyText');

        surnameError(true);
      } else if (
        values.surname.toString().length < 1 ||
        values.surname.toString().length > 100
      ) {
        errors.surname = t('feedback.surnameField.errorText');

        surnameError(true);
      } else if (!nameRegex.test(values.surname)) {
        errors.surname = t('feedback.surnameField.regexErrorText');

        surnameError(true);
      }
    }

    if (typeof cityError !== 'undefined') {
      cityError(false);

      if (!values.city?.toString().trim()) {
        errors.city = t('feedback.cityField.emptyText');

        cityError(true);
      } else if (
        values.city.toString().length < 1 ||
        values.city.toString().length > 100
      ) {
        errors.city = t('feedback.cityField.errorText');

        cityError(true);
      }
    }

    if (typeof streetError !== 'undefined') {
      streetError(false);

      if (!values.street?.toString().trim()) {
        errors.street = t('feedback.streetField.emptyText');

        streetError(true);
      } else if (
        values.street.toString().length < 1 ||
        values.street.toString().length > 100
      ) {
        errors.street = t('feedback.streetField.errorText');

        streetError(true);
      }
    }

    if (typeof houseError !== 'undefined') {
      houseError(false);

      if (!values.house?.toString().trim()) {
        errors.house = t('feedback.houseField.emptyText');

        houseError(true);
      } else if (
        values.house.toString().length < 1 ||
        values.house.toString().length > 100
      ) {
        errors.house = t('feedback.houseField.errorText');

        houseError(true);
      }
    }

    if (typeof flatError !== 'undefined') {
      flatError(false);

      if (values.flat?.toString().length > 100) {
        errors.flat = t('feedback.flatField.errorText');

        flatError(true);
      }
    }

    if (typeof organizationError !== 'undefined') {
      organizationError(false);

      if (values.organization?.toString().length > 100) {
        errors.organization = t('feedback.organizationField.errorText');

        organizationError(true);
      }
    }

    if (typeof noteError !== 'undefined') {
      noteError(false);

      if (values.note?.toString().length > 1000) {
        errors.organization = t('feedback.noteField.errorText');

        noteError(true);
      }
    }

    if (typeof dateError !== 'undefined') {
      dateError(false);

      if (!values.date) {
        errors.date = t('feedback.dateField.emptyText');

        dateError(true);
      }
    }

    if (typeof promocodeError !== 'undefined') {
      promocodeError(false);

      if (!values.promocode?.toString().trim()) {
        errors.promocode = t('feedback.promocode.emptyText');

        promocodeError(true);
      }
    }

    return errors;
  }, []);
};

export const handleSetErrorText = (data, t) => {
  if (data?.errors || data?.error instanceof Array) {
    const key = Object.keys(data.errors)[0],
      keyName = key.charAt(0).toUpperCase() + key.slice(1);

    return `${keyName}: ${data.errors[key][0]}`;
  }

  if (!data?.error || JSON.stringify(data.error) === '{}') {
    return t('feedback.unknownError', { ns: 'feedback' });
  }

  if (typeof data?.error === 'string') {
    return data?.error;
  }

  if (data?.error?.detail) {
    return data?.error.detail[0];
  }

  if (Object.keys(data.error).length !== 0) {
    const key = Object.keys(data.error)[0],
      keyName = key.charAt(0).toUpperCase() + key.slice(1);

    return `${keyName}: ${data.error[key][0]}`;
  }
};
