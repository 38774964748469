import { AxiosError, AxiosResponse } from 'axios';
import { httpClient } from 'helpers/httpClient';
import { NoAnswer } from 'interfaces/Feedback/Feedback';
import { getCookie } from 'cookies-next/lib';
import { authCookieName } from 'helpers/userAuth';
import {
  ActivateData,
  ConfirmData,
  SignInData,
  SignUpData,
} from 'interfaces/Modal/Auth';
import { useGetCurrentToken } from 'helpers/hooks/sendCurrentToken';

interface Token extends NoAnswer {
  auth_token?: string;
  locale?: string;
}

export const sendSignUpForm = async (
  data
): Promise<AxiosResponse<NoAnswer>> => {
  try {
    const config = {
      headers: useGetCurrentToken(),
      params: {
        locale: data.locale,
        geo: data.geo,
      },
    };

    const response = await httpClient.post<NoAnswer>(
      `/users/sign_up`,
      data.data,
      config
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const sendSignInForm = async (data): Promise<AxiosResponse<Token>> => {
  try {
    const config = {
      headers: useGetCurrentToken(),
      params: {
        locale: data.locale,
        geo: data.geo,
      },
    };

    const response = await httpClient.post<Token>(
      '/users/sign_in',
      data.data,
      config
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const sendActivateForm = async (
  data
): Promise<AxiosResponse<NoAnswer>> => {
  try {
    const token = getCookie(authCookieName);
    const config = {
      headers: {
        AUTHORIZATION: `Bearer ${token}`,
      },
    };
    const response = await httpClient.post<NoAnswer>(
      `/license_codes/activate?locale=${data.locale}`,
      data.data,
      config
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const sendConfirmForm = async (data): Promise<AxiosResponse<Token>> => {
  try {
    const config = {
      headers: useGetCurrentToken(),
      params: {
        locale: data.locale,
        geo: data.geo,
      },
    };
    const response = await httpClient.patch<Token>(
      `/users/confirm`,
      data.data,
      config
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const sendExitForm = async (data): Promise<AxiosResponse<NoAnswer>> => {
  try {
    const token = getCookie(authCookieName);
    const config = {
      headers: {
        AUTHORIZATION: `Bearer ${token}`,
      },
      data: data,
    };

    const response = await httpClient.delete<NoAnswer>(
      '/users/sign_out',
      config
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const submitSignUp = async (
  data: SignUpData,
  actions,
  {
    language,
    geo,
    beforeSend,
    successCallback,
    errorCallback,
    errorFieldCallback,
  }
) => {
  const { setSubmitting, resetForm } = actions;
  const finalData = {
    locale: language,
    geo: geo,
    data: {
      sign_up: {
        email: data.email,
        first_name: data.name,
        password: data.password,
      },
    },
  };
  const response = await sendSignUpForm(finalData);
  setSubmitting(false);
  beforeSend(data);

  if (!response?.status) {
    errorCallback({});
    return;
  }

  switch (response.status) {
    case 200:
    case 201:
      resetForm({});
      successCallback(response.data, finalData);
      break;

    case 422:
      errorFieldCallback(response.data);
      break;

    default:
      errorCallback(response.data);
      break;
  }
};

export const submitResendCode = async (
  data,
  { successCallback, errorFieldCallback }
) => {
  const response = await sendSignUpForm(data);

  if (!response?.status) {
    errorFieldCallback({});
    return;
  }

  switch (response.status) {
    case 200:
    case 201:
      successCallback();
      break;

    default:
      errorFieldCallback(response.data);
      break;
  }
};

export const submitSignIn = async (
  data: SignInData,
  actions,
  { language, geo, successCallback, errorCallback, errorFieldCallback }
) => {
  const { setSubmitting, resetForm } = actions;
  const finalData = {
    locale: language,
    geo: geo,
    data: {
      user: data,
    },
  };

  const response = await sendSignInForm(finalData);
  setSubmitting(false);

  if (!response?.status || !response.data) {
    errorCallback({});
    return;
  }

  switch (response.status) {
    case 200:
      resetForm({});
      successCallback(response.data);
      break;

    case 422:
      errorFieldCallback(response.data);
      break;

    default:
      errorCallback(response.data);
      break;
  }
};

export const submitActivate = async (
  data: ActivateData,
  actions,
  { language, successCallback, errorFieldCallback, beforeSubmit, afterSubmit }
) => {
  const { setSubmitting, resetForm } = actions;
  const finalData = {
    locale: language,
    data: {
      license_code: {
        code: data.licenseCode,
        device_id: getCookie('_ym_uid'),
      },
    },
  };
  beforeSubmit();
  const response = await sendActivateForm(finalData);
  afterSubmit();
  setSubmitting(false);

  if (!response?.status) {
    errorFieldCallback({});
    return;
  }

  switch (response.status) {
    case 200:
    case 204:
      resetForm({});
      successCallback();
      break;

    default:
      errorFieldCallback(response.data);
      break;
  }
};

export const submitConfirm = async (
  data: ConfirmData,
  actions,
  {
    language,
    email,
    successCallback,
    errorFieldCallback,
    beforeSubmit,
    afterSubmit,
    geo,
  }
) => {
  const { setSubmitting, resetForm } = actions;
  const finalData = {
    locale: language,
    geo: geo,
    data: {
      user: {
        email: email,
        code: data.code.toString(),
      },
    },
  };

  beforeSubmit();
  const response = await sendConfirmForm(finalData);
  afterSubmit();
  setSubmitting(false);

  if (!response?.status) {
    errorFieldCallback({});
    return;
  }

  switch (response.status) {
    case 200:
      resetForm({});
      successCallback(response.data);
      break;

    default:
      errorFieldCallback(response.data);
      break;
  }
};

export const exitSubmit = async ({ successCallback }) => {
  const response = await sendExitForm({});

  if (response) {
    switch (response.status) {
      case 200:
      case 204:
        successCallback();
        break;
    }
  }
};
