import React from 'react';

export const handleChangeCode = (
  e: { target: HTMLInputElement },
  limit: number,
  element: React.RefObject<HTMLInputElement>
) => {
  if (!element.current) {
    return;
  }

  let value = e.target.value.toString().toUpperCase();

  if (value.length >= limit) {
    value = value.slice(0, limit);
    element.current.setSelectionRange(0, 0);
    element.current.blur();
  }

  const [match] = value.match(/^(\d|\w)*/);
  if (typeof match === 'undefined') {
    value = '';
  } else {
    value = value
      .toString()
      .slice(0, value.length - (value.length - match.length));
  }

  e.target.value = value;
};
