import React from 'react';

const WhatsAppIcon: React.FC = () => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='19'
      fill='none'
      viewBox='0 0 18 19'
    >
      <path
        fill='#34C759'
        d='M15.3 2.8A8.835 8.835 0 0 0 8.991.183C4.077.182.072 4.187.072 9.1c0 1.575.414 3.105 1.188 4.455L0 18.182l4.725-1.242a8.921 8.921 0 0 0 4.266 1.089c4.914 0 8.919-4.005 8.919-8.92a8.863 8.863 0 0 0-2.61-6.308ZM8.991 16.518a7.436 7.436 0 0 1-3.78-1.035l-.27-.162-2.808.738.747-2.736-.18-.28a7.438 7.438 0 0 1-1.134-3.941c0-4.086 3.33-7.416 7.416-7.416 1.98 0 3.843.774 5.238 2.178a7.365 7.365 0 0 1 2.169 5.247c.018 4.086-3.312 7.407-7.398 7.407Zm4.068-5.544c-.225-.108-1.323-.648-1.521-.73-.207-.071-.351-.107-.504.109-.153.225-.576.729-.702.873-.126.153-.261.17-.486.054-.225-.108-.945-.351-1.791-1.107-.666-.594-1.107-1.323-1.242-1.548-.126-.225-.018-.342.099-.46.099-.098.225-.26.333-.386.108-.126.153-.225.225-.37.072-.152.036-.278-.018-.386a74.099 74.099 0 0 1-.684-1.656c-.18-.432-.369-.378-.504-.387h-.432a.808.808 0 0 0-.594.279c-.198.225-.774.765-.774 1.863 0 1.098.801 2.16.909 2.304.108.153 1.575 2.403 3.807 3.366.531.234.945.369 1.269.468.531.17 1.017.144 1.404.09.432-.063 1.323-.54 1.503-1.062.189-.522.189-.963.126-1.062-.063-.1-.198-.144-.423-.252Z'
      />
    </svg>
  );
};

export default WhatsAppIcon;
