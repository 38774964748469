import { AxiosError, AxiosResponse } from 'axios';
import { httpClient } from 'helpers/httpClient';
import { NoAnswer } from 'interfaces/Feedback/Feedback';
import {
  RecoveryCodeData,
  RecoveryEmailData,
  RenewPasswordData,
} from 'interfaces/Modal/RecoveryPassword';
import {
  OldPasswordData,
  ConfirmPasswordData,
} from 'interfaces/Modal/RecoveryPassword';
import { getCookie } from 'cookies-next/lib';
import { authCookieName } from 'helpers/userAuth';

interface Token extends NoAnswer {
  reset_token?: string;
}

export const sendEmail = async (data): Promise<AxiosResponse<NoAnswer>> => {
  try {
    const response = await httpClient.post<NoAnswer>(
      `/users/send_reset?locale=${data.locale}`,
      data.data
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const sendCode = async (data): Promise<AxiosResponse<Token>> => {
  try {
    const response = await httpClient.patch<Token>(
      `/users/reset_confirmation?locale=${data.locale}`,
      data.data
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const sendPassword = async (data): Promise<AxiosResponse<NoAnswer>> => {
  try {
    const response = await httpClient.patch<NoAnswer>(
      `/users/reset_password?locale=${data.locale}`,
      data.data
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const submitEmail = async (
  data: RecoveryEmailData,
  actions,
  {
    language,
    setEmail,
    successCallback,
    errorCallback,
    beforeSubmit,
    afterSubmit,
  }
) => {
  setEmail(data.email);
  const { setSubmitting, resetForm } = actions;
  const finalData = {
    locale: language,
    data: {
      user: data,
    },
  };
  beforeSubmit();
  const response = await sendEmail(finalData);
  afterSubmit();
  setSubmitting(false);

  if (!response?.status) {
    errorCallback({});
    return;
  }

  switch (response.status) {
    case 200:
    case 201:
      resetForm({});
      successCallback();
      break;

    default:
      errorCallback(response.data);
      break;
  }
};

export const resendCode = async (
  data: RecoveryEmailData,
  { language, successCallback, errorCallback, beforeSubmit, afterSubmit }
) => {
  const finalData = {
    locale: language,
    data: {
      user: data,
    },
  };

  beforeSubmit();
  const response = await sendEmail(finalData);
  afterSubmit();

  if (!response?.status) {
    errorCallback({});
    return;
  }

  switch (response.status) {
    case 200:
    case 201:
      successCallback();
      break;

    default:
      errorCallback(response.data);
      break;
  }
};

export const submitCode = async (
  data: RecoveryCodeData,
  actions,
  { language, email, successCallback, errorCallback, beforeSubmit, afterSubmit }
) => {
  const { setSubmitting, resetForm } = actions;
  const finalData = {
    locale: language,
    data: {
      user: {
        email: email,
        code: data.code,
      },
    },
  };

  beforeSubmit();
  const response = await sendCode(finalData);
  afterSubmit();
  setSubmitting(false);

  if (!response?.status || !response.data) {
    errorCallback({});
    return;
  }

  switch (response.status) {
    case 200:
    case 204:
      resetForm({});
      successCallback(response.data);
      break;

    default:
      errorCallback(response.data);
      break;
  }
};

export const submitPassword = async (
  data: RenewPasswordData,
  actions,
  {
    language,
    email,
    token,
    successCallback,
    errorCallback,
    beforeSubmit,
    afterSubmit,
  }
) => {
  const { setSubmitting, resetForm } = actions;
  const finalData = {
    locale: language,
    data: {
      user: {
        email: email,
        password: data.password,
        reset_token: token,
      },
    },
  };

  beforeSubmit();
  const response = await sendPassword(finalData);
  afterSubmit();
  setSubmitting(false);

  if (!response?.status) {
    errorCallback({});
    return;
  }

  switch (response.status) {
    case 200:
    case 204:
      resetForm({});
      successCallback();
      break;

    default:
      errorCallback(response.data);
      break;
  }
};

export const sendOldPassword = async (data): Promise<AxiosResponse<Token>> => {
  try {
    const token = getCookie(authCookieName);
    const config = {
      headers: {
        AUTHORIZATION: `Bearer ${token}`,
      },
    };
    const response = await httpClient.post<Token>(
      `/users/reset_with_old_password?locale=${data.locale}`,
      data.data,
      config
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const sendPasswordConfirm = async (
  data
): Promise<AxiosResponse<NoAnswer>> => {
  try {
    const token = getCookie(authCookieName);
    const config = {
      headers: {
        AUTHORIZATION: `Bearer ${token}`,
      },
    };
    const response = await httpClient.patch<NoAnswer>(
      `/users/change_password?locale=${data.locale}`,
      data.data,
      config
    );

    return response;
  } catch (e) {
    return e.response as AxiosError<unknown> as never;
  }
};

export const submitOldPassword = async (
  data: OldPasswordData,
  actions,
  { language, successCallback, errorCallback, beforeSubmit, afterSubmit }
) => {
  const { setSubmitting, resetForm } = actions;
  const finalData = {
    locale: language,
    data: {
      user: {
        old_password: data.password,
      },
    },
  };

  beforeSubmit();
  const response = await sendOldPassword(finalData);

  afterSubmit();
  setSubmitting(false);

  if (!response?.status || !response.data) {
    errorCallback({});
    return;
  }

  switch (response.status) {
    case 200:
      resetForm({});
      successCallback(response.data);
      break;

    default:
      errorCallback(response.data);
      break;
  }
};

export const submitPasswordConfirm = async (
  data: ConfirmPasswordData,
  actions,
  { language, token, successCallback, errorCallback, beforeSubmit, afterSubmit }
) => {
  const { setSubmitting, resetForm } = actions;
  const finalData = {
    locale: language,
    data: {
      user: {
        reset_token: token,
        new_password: data.password,
        new_password_confirmation: data.password_confirmation,
      },
    },
  };

  beforeSubmit();
  const response = await sendPasswordConfirm(finalData);

  afterSubmit();
  setSubmitting(false);

  if (!response?.status) {
    errorCallback({});
    return;
  }

  switch (response.status) {
    case 200:
    case 204:
      resetForm({});
      successCallback();
      break;

    default:
      errorCallback(response.data);
      break;
  }
};
