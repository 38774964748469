import React, { useContext } from 'react';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Button from 'components/blocks/Button';
import Modal from 'components/blocks/Modal';

import styles from './ModalSelection.module.scss';
import buttonStyles from 'components/blocks/Button/Button.module.scss';
import modalStyles from 'components/blocks/Modal/ModalMessage/ModalMessage.module.scss';

const ModalSelection: React.FC = () => {
  const { modalType, setModalType, modalSelectData } = useContext(AppContext);

  return (
    <Modal
      isOpen={modalType === MODAL.selection}
      additionalClasses={[styles.modalSelection__container]}
    >
      <h2 className={modalStyles.modalMessage__title}>
        {modalSelectData?.title}
      </h2>
      <p className={modalStyles.modalMessage__subtitle}>
        {modalSelectData?.subTitle}
      </p>
      <div className={styles.modalSelection__buttons}>
        <Button
          placeholder={modalSelectData?.buttonActionText}
          addStyles={[
            buttonStyles.button__transparent,
            styles.modalSelection__button,
          ]}
          onClick={() => {
            modalSelectData?.buttonActionEffect();
            setModalType('');
          }}
        />
        <Button
          placeholder={modalSelectData?.buttonCloseText}
          addStyles={[styles.modalSelection__button]}
          onClick={() => setModalType('')}
        />
      </div>
      <div className='closeButton' onClick={() => setModalType('')} />
    </Modal>
  );
};

export default ModalSelection;
