import React, { useCallback, useContext, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormValidate, handleSetErrorText } from 'helpers/hooks/form';
import { useSelector } from 'react-redux';
import { submitSignUp, submitSignIn } from 'services/authService';
import { useRouter } from 'next/router';
import { IUserStore } from 'stores/userStore';
import { RootState } from 'stores/store';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Button from 'components/blocks/Button';
import Modal from 'components/blocks/Modal';
import { Field, Form, Formik } from 'formik';
import FormInput from 'components/blocks/FormInput';
import ModalRecoveryPassword from 'components/blocks/Modal/ModalRecoveryPassword';

import styles from 'components/blocks/Modal/ModalAuth/ModalAuth.module.scss';
import inputStyles from 'components/blocks/FormInput/FormInput.module.scss';

interface IModalAuthLogin {
  successSignIn: (value) => void;
  setCurrentUserEmail: (value) => void;
  setSignUpResendData: (value) => void;
}

const ModalAuthLogin: React.FC<IModalAuthLogin> = ({
  successSignIn,
  setCurrentUserEmail,
  setSignUpResendData,
}) => {
  const { t } = useTranslation(['modal', 'feedback']);
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };

  const geoStored = useSelector<RootState, IUserStore>(state => state.user);
  const geoDataStored = geoStored.geoData;

  const { modalType, setModalType, modalAuthAfterSuccess } =
    useContext(AppContext);

  const [emailError, setEmailError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [nameError, setNameError] = useState();
  const [privacyCheckboxError, setPrivacyCheckboxError] = useState(false);

  const [responseFieldError, setResponseFieldError] = useState('');
  const [responseError, setResponseError] = useState('');

  const validateSignIn = useFormValidate({
    emailError: setEmailError,
    passwordError: setPasswordError,
  });

  const validateSignUp = useFormValidate({
    emailError: setEmailError,
    nameError: setNameError,
    passwordError: setPasswordError,
    privacyCheckboxError: setPrivacyCheckboxError,
  });

  const handleValidate = values => {
    if (responseFieldError) {
      setResponseFieldError('');
    }

    if (responseError) {
      setResponseError('');
    }

    switch (modalType) {
      case MODAL.auth.signIn:
        return validateSignIn(values);

      case MODAL.auth.signUp:
        return validateSignUp(values);
    }
  };

  const submitErrorField = data => {
    setResponseFieldError(handleSetErrorText(data, t));
  };

  const submitError = data => {
    setResponseError(handleSetErrorText(data, t));
  };

  const handleSubmitSignUp = useCallback(
    async (data, actions) => {
      submitSignUp(data, actions, {
        language: currentLanguage,
        geo: geoDataStored,
        beforeSend: data => {
          setCurrentUserEmail(data.email);
        },
        successCallback: (data, finalData) => {
          setSignUpResendData(finalData);
          setModalType(MODAL.auth.confirm);
        },
        errorFieldCallback: submitErrorField,
        errorCallback: submitError,
      });
    },
    [currentLanguage, geoDataStored]
  );

  const handleSubmitSignIn = useCallback(
    async (data, actions) => {
      submitSignIn(data, actions, {
        language: currentLanguage,
        geo: geoDataStored,
        successCallback: successSignIn,
        errorFieldCallback: submitErrorField,
        errorCallback: submitError,
      });
    },
    [currentLanguage, modalAuthAfterSuccess, geoDataStored]
  );

  const showPasswordModal = () => {
    setModalType('');
    setModalType(MODAL.recoveryPassword.email);
  };

  const auth_socials = (
    <div className={styles.modalAuth__socials}>
      <div className={styles.modalAuth__socialsLine}>
        <a
          href={`/api/v1/users/auth/google_oauth2_for_website?locale=${currentLanguage}&geo=${geoDataStored}`}
          target='_blank'
          className={styles.modalAuth__social}
        >
          <img
            src='/images/icons/auth-google.svg'
            alt='Google'
            className='img'
          />
        </a>
        <a
          href={`/api/v1/users/auth/apple_for_website?locale=${currentLanguage}&geo=${geoDataStored}`}
          target='_blank'
          className={styles.modalAuth__social}
        >
          <img src='/images/icons/auth-apple.svg' alt='Apple' className='img' />
        </a>
        {/*<a
          href={`/api/v1/users/auth/lichess?locale=${currentLanguage}&geo=${geoDataStored}`}
          target='_blank'
          className={styles.modalAuth__social}
        >
          <img
            src='/images/icons/auth-lichess.svg'
            alt='lichess'
            className='img'
          />
        </a>*/}
        <a
          href={`/api/v1/users/auth/vkontakte_for_website?locale=${currentLanguage}&geo=${geoDataStored}`}
          target='_blank'
          className={styles.modalAuth__social}
        >
          <img
            src='/images/icons/auth-vk.svg'
            alt='Vkontakte'
            className='img'
          />
        </a>
      </div>
      <div className={styles.modalAuth__socialsText}>
        <span>{t('modal.sign_in.add_email')}</span>
      </div>
    </div>
  );

  return (
    <>
      <Modal
        isOpen={
          modalType === MODAL.auth.signIn || modalType === MODAL.auth.signUp
        }
        additionalClasses={[styles.modalAuth, styles[modalType]]}
      >
        <div className={styles.modalAuth__tabs}>
          <button
            className={cn(styles.modalAuth__tab, {
              [styles.active]: modalType === MODAL.auth.signUp,
            })}
            onClick={() => {
              setModalType(MODAL.auth.signUp);
            }}
          >
            {t('modal.sign_up.title')}
          </button>
          <button
            className={cn(styles.modalAuth__tab, {
              [styles.active]: modalType === MODAL.auth.signIn,
            })}
            onClick={() => {
              setModalType(MODAL.auth.signIn);
            }}
          >
            {t('modal.sign_in.title')}
          </button>
        </div>
        {auth_socials}
        {modalType === MODAL.auth.signIn && (
          <>
            <Formik
              initialValues={{
                email: '',
                password: '',
              }}
              validate={handleValidate}
              onSubmit={handleSubmitSignIn}
            >
              {({ isSubmitting, dirty, isValid, setFieldTouched }) => (
                <Form className={styles.modalAuth__form}>
                  <FormInput
                    type='email'
                    name='email'
                    placeholder={t('modal.fields.email.placeholder')}
                    error={emailError}
                    addContainerClasses={[styles.modalAuth__field]}
                    onBlur={() => setFieldTouched('email')}
                  />
                  {responseFieldError && (
                    <div
                      className={cn(
                        inputStyles.formInput__errorLabel,
                        inputStyles.formInput__errorLabelStatic
                      )}
                    >
                      {responseFieldError}
                    </div>
                  )}
                  <FormInput
                    type='password'
                    name='password'
                    placeholder={t('feedback.passwordField.inputText', {
                      ns: 'feedback',
                    })}
                    error={passwordError}
                    addContainerClasses={[styles.modalAuth__field]}
                    onBlur={() => setFieldTouched('password')}
                  />
                  <div
                    className={styles.modalAuth__forgotPassword}
                    onClick={() => showPasswordModal()}
                  >
                    {t('modal.sign_in.forgot_password')}
                  </div>
                  <Button
                    placeholder={t('modal.sign_in.enter')}
                    disabled={isSubmitting || !(dirty && isValid)}
                    type='submit'
                    addStyles={[styles.modalAuth__submit]}
                  />
                  {responseError && (
                    <div
                      className={cn(
                        inputStyles.formInput__errorLabel,
                        inputStyles.formInput__errorLabelStatic
                      )}
                    >
                      {responseError}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </>
        )}
        {modalType === MODAL.auth.signUp && (
          <>
            <Formik
              initialValues={{
                email: '',
                name: '',
                password: '',
                data_processing: true,
              }}
              validate={handleValidate}
              onSubmit={handleSubmitSignUp}
            >
              {({ isSubmitting, dirty, isValid, setFieldTouched }) => (
                <Form className={styles.modalAuth__form}>
                  <FormInput
                    type='email'
                    name='email'
                    placeholder={t('modal.fields.email.placeholder')}
                    error={emailError}
                    addContainerClasses={[styles.modalAuth__field]}
                    onBlur={() => setFieldTouched('email')}
                  />
                  {responseFieldError && (
                    <div
                      className={cn(
                        inputStyles.formInput__errorLabel,
                        inputStyles.formInput__errorLabelStatic
                      )}
                    >
                      {responseFieldError}
                    </div>
                  )}
                  <FormInput
                    type='name'
                    name='name'
                    placeholder={t('modal.fields.name.placeholder')}
                    error={nameError}
                    addContainerClasses={[styles.modalAuth__field]}
                    onBlur={() => setFieldTouched('name')}
                  />
                  <FormInput
                    type='password'
                    name='password'
                    placeholder={t('feedback.passwordField.inputText', {
                      ns: 'feedback',
                    })}
                    error={passwordError}
                    addContainerClasses={[styles.modalAuth__field]}
                    onBlur={() => setFieldTouched('password')}
                  />
                  <div className={styles.organizersFeedback__checkPrivacy}>
                    <Field
                      id='privacyCheckboxRegistry'
                      type='checkbox'
                      name='data_processing'
                    />
                    <label
                      htmlFor='privacyCheckboxRegistry'
                      className={cn(styles.modalAuth__privacyLabel, {
                        [styles.error]: privacyCheckboxError,
                      })}
                    >
                      <span>
                        {t('modal.sign_up.policy.0')}
                        <a
                          target='_blank'
                          href='/privacy'
                          className={cn('link', 'link-underline', 'text-green')}
                        >
                          {t('modal.sign_up.policy.1')}
                        </a>
                        {t('modal.sign_up.policy.2')}
                        <a
                          target='_blank'
                          href='/terms'
                          className={cn('link', 'link-underline', 'text-green')}
                        >
                          {t('modal.sign_up.policy.3')}
                        </a>
                      </span>
                    </label>
                  </div>
                  <Button
                    placeholder={t('modal.sign_up.registration')}
                    disabled={isSubmitting || !(dirty && isValid)}
                    type='submit'
                    addStyles={[styles.modalAuth__submit]}
                  />
                  {responseError && (
                    <div
                      className={cn(
                        inputStyles.formInput__errorLabel,
                        inputStyles.formInput__errorLabelStatic
                      )}
                    >
                      {responseError}
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </>
        )}
        <div className='closeButton' onClick={() => setModalType('')} />
      </Modal>
      <ModalRecoveryPassword />
    </>
  );
};

export default ModalAuthLogin;
