export const storeLinks = {
  googlePlay: {
    link: 'https://play.google.com/store/apps/details?id=com.idchess.idchess',
    alt: 'Google Play',
    imageNameWhite: 'google_play_white.svg',
    imageNameBlack: 'google_play.svg',
  },
  appStore: {
    link: 'https://apps.apple.com/ru/app/idchess-play-and-learn-chess/id1464126978',
    alt: 'App Store',
    imageNameWhite: 'app_store_white.svg',
    imageNameBlack: 'app_store.png',
  },
  appGallery: {
    link: 'https://appgallery.huawei.com/#/app/C105818083',
    alt: 'App Gallery',
    imageNameWhite: 'app_gallery_white.svg',
    imageNameBlack: 'app_gallery.svg',
  },
  ruStore: {
    link: 'https://apps.rustore.ru/app/com.idchess.idchess',
    alt: 'RuStore',
    imageNameWhite: 'ru_store_white.svg',
    imageNameBlack: 'ru_store.svg',
  },
};

export const socialLinks = {
  telegram: 'https://t.me/idchess',
  telegramName: '@idchess_support',
  whatsapp: 'https://api.whatsapp.com/send?phone=37443216853',
  phone: '+374 43 216853',
  mail: 'mailto:hello@idchess.com',
};

export const importantAppLinks = {
  app: 'https://live.idchess.com/',
  fideUsageArticle: 'https://www.fide.com/news/2491',
};
