import React, { useCallback, useContext, useEffect, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormValidate, handleSetErrorText } from 'helpers/hooks/form';
import {
  submitEmail,
  resendCode,
  submitCode,
  submitPassword,
} from 'services/recoveryPasswordService';
import { useRouter } from 'next/router';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Button from 'components/blocks/Button';
import Modal from 'components/blocks/Modal';
import { Form, Formik } from 'formik';
import FormInput from 'components/blocks/FormInput';

import styles from './ModalRecoveryPassword.module.scss';
import modalStyles from 'components/blocks/Modal/Modal.module.scss';
import formStyles from 'components/blocks/Field/Field.module.scss';
import inputStyles from 'components/blocks/FormInput/FormInput.module.scss';

const ModalRecoveryPassword: React.FC = () => {
  const { t } = useTranslation(['modal', 'feedback']);
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };
  const { modalType, setModalType, setModalMessageData } =
    useContext(AppContext);

  const [timer, setTimer] = useState(60);
  const [currentUserEmail, setCurrentUserEmail] = useState('');
  const [recoveryToken, setRecoveryToken] = useState('');

  const [emailError, setEmailError] = useState();
  const [codeError, setCodeError] = useState();
  const [passwordError, setPasswordError] = useState();
  const [responseError, setResponseError] = useState('');

  const [isWaiting, setWaiting] = useState(false);

  const messageParams = {
    title: t('modal.recoveryPassword.success_title'),
    subTitle: '',
    buttonText: t('modal.recoveryPassword.next'),
  };

  const handleChangeCode = e => {
    let value = e.target.value.toString();

    if (value.length >= 6) {
      value = value.slice(0, 6);
    }

    value = parseInt(`1${value}`);

    if (isNaN(value)) {
      value = '';
    }

    value = value.toString().slice(1, 7);

    e.target.value = value;
  };

  const validateEmail = useFormValidate({
    emailError: setEmailError,
  });

  const validateCode = useFormValidate({
    codeError: setCodeError,
  });

  const validatePassword = useFormValidate({
    passwordError: setPasswordError,
  });

  const beforeSubmit = () => {
    setWaiting(true);
  };

  const afterSubmit = () => {
    setWaiting(false);
  };

  const errorCallback = data => {
    setResponseError(handleSetErrorText(data, t));
  };

  const handleSubmitEmail = useCallback(
    async (data, actions) => {
      submitEmail(data, actions, {
        language: currentLanguage,
        setEmail: setCurrentUserEmail,
        successCallback: () => {
          setModalType(MODAL.recoveryPassword.code);
        },
        errorCallback: errorCallback,
        beforeSubmit: beforeSubmit,
        afterSubmit: afterSubmit,
      });
    },
    [currentLanguage]
  );

  const handleResendCode = useCallback(
    async data => {
      resendCode(data, {
        language: currentLanguage,
        successCallback: () => {
          setTimer(60);
        },
        errorCallback: errorCallback,
        beforeSubmit: beforeSubmit,
        afterSubmit: afterSubmit,
      });
    },
    [currentLanguage]
  );

  const handleSubmitCode = useCallback(
    async (data, actions) => {
      submitCode(data, actions, {
        language: currentLanguage,
        email: currentUserEmail,
        successCallback: data => {
          setRecoveryToken(data.reset_token);
          setModalType(MODAL.recoveryPassword.renew);
        },
        errorCallback: errorCallback,
        beforeSubmit: beforeSubmit,
        afterSubmit: afterSubmit,
      });
    },
    [currentLanguage, currentUserEmail]
  );

  const handleSubmitPassword = useCallback(
    async (data, actions) => {
      submitPassword(data, actions, {
        language: currentLanguage,
        email: currentUserEmail,
        token: recoveryToken,
        successCallback: () => {
          setModalMessageData(messageParams);
          setModalType(MODAL.success);
        },
        errorCallback: errorCallback,
        beforeSubmit: beforeSubmit,
        afterSubmit: afterSubmit,
      });
    },
    [currentLanguage, currentUserEmail, recoveryToken]
  );

  const handleValidate = values => {
    if (responseError) {
      setResponseError('');
    }

    if (modalType === MODAL.recoveryPassword.email) {
      return validateEmail(values);
    }

    if (modalType === MODAL.recoveryPassword.code) {
      return validateCode(values);
    }

    if (modalType === MODAL.recoveryPassword.renew) {
      return validatePassword(values);
    }
  };

  useEffect(() => {
    if (!modalType) {
      return;
    }

    if (modalType === MODAL.recoveryPassword.code && timer > 0) {
      setTimeout(() => {
        setTimer(timer - 1);
      }, 1000);
    }
  }, [modalType, timer, modalType]);

  return (
    <>
      <Modal
        isOpen={
          modalType === MODAL.recoveryPassword.email ||
          modalType === MODAL.recoveryPassword.code ||
          modalType === MODAL.recoveryPassword.renew
        }
        additionalClasses={[modalStyles.modal__contentCommon]}
        isWaiting={isWaiting}
      >
        <div className={modalStyles.modal__title}>
          {modalType === MODAL.recoveryPassword.renew
            ? t('modal.recoveryPassword.renew_title')
            : t('modal.recoveryPassword.title')}
        </div>
        {modalType === MODAL.recoveryPassword.email && (
          <Formik
            initialValues={{
              email: '',
            }}
            validate={handleValidate}
            onSubmit={handleSubmitEmail}
          >
            {({ isSubmitting, dirty, isValid, setFieldTouched }) => (
              <Form>
                <FormInput
                  type='email'
                  name='email'
                  placeholder={t('modal.fields.email.placeholder')}
                  error={emailError}
                  label={t('modal.fields.email.label')}
                  addContainerClasses={[styles.modalRecoveryPassword__field]}
                  onBlur={() => setFieldTouched('email')}
                />
                {responseError && (
                  <div
                    className={cn(
                      inputStyles.formInput__errorLabel,
                      inputStyles.formInput__errorLabelStatic
                    )}
                  >
                    {responseError}
                  </div>
                )}
                <Button
                  placeholder={t('modal.recoveryPassword.next')}
                  disabled={isSubmitting || !(dirty && isValid)}
                  type='submit'
                  addStyles={[styles.modalRecoveryPassword__button]}
                />
              </Form>
            )}
          </Formik>
        )}
        {modalType === MODAL.recoveryPassword.code && (
          <>
            <div className={modalStyles.modal__subtitle}>
              {t('modal.recoveryPassword.code')}{' '}
              <span className='text-green'>{currentUserEmail}</span>
            </div>
            <Formik
              initialValues={{
                code: '',
              }}
              validate={handleValidate}
              onSubmit={handleSubmitCode}
            >
              {({ isSubmitting, dirty, isValid, setFieldTouched }) => (
                <Form>
                  <FormInput
                    type='text'
                    name='code'
                    placeholder='000000'
                    error={codeError}
                    addClasses={[formStyles.field__inputCode]}
                    addContainerClasses={[formStyles.field__inputCodeContainer]}
                    onInput={e => {
                      handleChangeCode(e);
                    }}
                    inputMode={'numeric'}
                    onBlur={() => setFieldTouched('code')}
                  />
                  {responseError && (
                    <div
                      className={cn(
                        inputStyles.formInput__errorLabel,
                        inputStyles.formInput__errorLabelStatic
                      )}
                    >
                      {responseError}
                    </div>
                  )}
                  <div className={modalStyles.modal__resend}>
                    {timer === 0 && (
                      <div
                        className={cn('text-green', 'link')}
                        onClick={() =>
                          handleResendCode({ email: currentUserEmail })
                        }
                      >
                        {t('modal.recoveryPassword.resend_code')}
                      </div>
                    )}
                    {timer > 0 && (
                      <>
                        {t('modal.recoveryPassword.resend_code_submit')}{' '}
                        <span className='text-green'>{timer}s</span>
                      </>
                    )}
                  </div>
                  <Button
                    placeholder={t('modal.recoveryPassword.next')}
                    disabled={isSubmitting || !(dirty && isValid)}
                    type='submit'
                    addStyles={[
                      styles.modalRecoveryPassword__button,
                      styles.modalRecoveryPassword__buttonCode,
                    ]}
                  />
                </Form>
              )}
            </Formik>
          </>
        )}
        {modalType === MODAL.recoveryPassword.renew && (
          <Formik
            initialValues={{
              password: '',
            }}
            validate={handleValidate}
            onSubmit={handleSubmitPassword}
          >
            {({ isSubmitting, dirty, isValid, setFieldTouched }) => (
              <Form>
                <FormInput
                  type='password'
                  name='password'
                  placeholder={t(
                    'modal.recoveryPassword.new_password_placeholder'
                  )}
                  error={passwordError}
                  label={t('modal.recoveryPassword.new_password')}
                  onBlur={() => setFieldTouched('password')}
                />
                {responseError && (
                  <div
                    className={cn(
                      inputStyles.formInput__errorLabel,
                      inputStyles.formInput__errorLabelStatic
                    )}
                  >
                    {responseError}
                  </div>
                )}
                <Button
                  placeholder={t('modal.recoveryPassword.change')}
                  disabled={isSubmitting || !(dirty && isValid)}
                  type='submit'
                  addStyles={[
                    styles.modalRecoveryPassword__button,
                    styles.modalRecoveryPassword__buttonCode,
                  ]}
                />
              </Form>
            )}
          </Formik>
        )}
        <div className='closeButton' onClick={() => setModalType('')} />
      </Modal>
    </>
  );
};

export default ModalRecoveryPassword;
