import { useCallback, useEffect, useState } from 'react';

export const useIsMobile = (minDesktopWidth: number) => {
  const [isMobile, setIsMobile] = useState(false);

  const checkMobile = useCallback(() => {
    if (document.documentElement.clientWidth < minDesktopWidth) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, []);

  useEffect(() => {
    if (process.browser) {
      checkMobile();
      window.addEventListener('resize', checkMobile);
    }
    return () => {
      if (process.browser) {
        window.removeEventListener('resize', checkMobile);
      }
    };
  }, [isMobile]);

  return isMobile;
};
