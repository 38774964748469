import React, { useEffect, useState } from 'react';
import cn from 'classnames';

import ReactModal from 'react-modal';

import styles from './Modal.module.scss';
import modalStyles from 'components/blocks/Modal/Modal.module.scss';

interface ModalProps {
  isOpen: boolean;
  additionalClasses?: string[];
  onAfterOpen?: () => void;
  isWaiting?: boolean;
}

const Modal: React.FC<ModalProps> = ({
  children,
  isOpen,
  additionalClasses = [],
  onAfterOpen,
  isWaiting,
}) => {
  const [showLoad, setShowLoad] = useState(false);

  useEffect(() => {
    if (!isWaiting) {
      setShowLoad(false);
      return;
    }

    // если 3 секунды и больше выполняется запрос, показываем загрузку
    const timeout = setTimeout(() => {
      if (isWaiting) {
        setShowLoad(true);
      }
    }, 3000);

    return () => clearTimeout(timeout);
  }, [isWaiting]);

  return (
    <ReactModal
      isOpen={isOpen}
      className={cn(modalStyles.modal__content, ...additionalClasses, {
        [styles.modal__contentLoad]: showLoad,
      })}
      bodyOpenClassName={'lock'}
      htmlOpenClassName={'lock'}
      overlayClassName={modalStyles.modal}
      onAfterOpen={onAfterOpen}
      ariaHideApp={false}
    >
      {showLoad && <div className={styles.modal__load} />}
      {children}
    </ReactModal>
  );
};

export default Modal;
