export const links = {
  news: 'news',
  organizers: 'organizers',
  var: 'var',
  shop: 'shop',
  reviews: 'reviews',
  guide: 'guide',
  faq: 'faq',
  blog: 'blog',
};
