import React from 'react';

const TelegramIcon: React.FC = () => {
  return (
    <svg
      fill='none'
      width='29'
      height='24'
      viewBox='0 0 29 24'
      id='tg'
      role='img'
      focusable='false'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M2.17485 10.4895C9.69103 7.21484 14.703 5.05597 17.2107 4.01291C24.3709 1.03477 25.8587 0.51743 26.8284 0.500166C27.0417 0.496591 27.5186 0.549448 27.8275 0.800106C28.0883 1.01176 28.1601 1.29767 28.1944 1.49833C28.2288 1.699 28.2715 2.15613 28.2376 2.51331C27.8495 6.59016 26.1706 16.4836 25.3165 21.0498C24.9551 22.9819 24.2434 23.6297 23.5545 23.6931C22.0572 23.8309 20.9203 22.7036 19.4701 21.753C17.2009 20.2655 15.919 19.3395 13.7163 17.888C11.1707 16.2105 12.8209 15.2885 14.2716 13.7818C14.6513 13.3875 21.2482 7.38705 21.3759 6.84272C21.3918 6.77465 21.4067 6.52089 21.2559 6.38689C21.1052 6.25289 20.8827 6.29872 20.7221 6.33516C20.4945 6.38681 16.8694 8.78287 9.84681 13.5233C8.81784 14.2299 7.88583 14.5742 7.05078 14.5561C6.1302 14.5362 4.35939 14.0356 3.04297 13.6077C1.42832 13.0828 0.145038 12.8053 0.256783 11.914C0.314987 11.4497 0.954344 10.9749 2.17485 10.4895Z'
        fill='white'
      />
    </svg>
  );
};

export default TelegramIcon;
