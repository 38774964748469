import React, { useContext } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Button from 'components/blocks/Button';
import Modal from 'components/blocks/Modal';

import styles from 'components/blocks/Modal/ModalAuth/ModalAuth.module.scss';
import modalStyles from 'components/blocks/Modal/Modal.module.scss';

const ModalAuthSuccess: React.FC = () => {
  const { t } = useTranslation(['modal', 'feedback']);
  const { modalType, setModalType } = useContext(AppContext);

  return (
    <Modal
      isOpen={modalType === MODAL.auth.activateSuccess}
      additionalClasses={[
        modalStyles.modal__contentCommon,
        styles.modalAuth__activateSuccess,
      ]}
    >
      <h3
        className={cn(
          'title__secondary',
          styles.modalAuth__title,
          styles.modalAuth__titleConfirm
        )}
      >
        {t('modal.activate.title_finish')}
      </h3>
      <Button
        placeholder={t('modal.activate.back')}
        onClick={() => setModalType('')}
        addStyles={[styles.modalAuth__activateButton]}
      />
      <div className='closeButton' onClick={() => setModalType('')} />
    </Modal>
  );
};

export default ModalAuthSuccess;
