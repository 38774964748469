import React, { useEffect } from 'react';
import { IModalState } from 'interfaces/Modal/Modal';

import Success from 'components/blocks/Icons/Success';

import styles from './Notification.module.scss';

interface INotification extends IModalState {
  text: string;
}

const Notification: React.FC<INotification> = ({
  isOpen,
  changeModalState,
  text,
}) => {
  const timeout = 2000;

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    setTimeout(() => {
      changeModalState(false);
    }, timeout);
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div className={styles.notification}>
          <Success />
          <div className={styles.notification__text}>{text}</div>
          <div
            className={styles.notification__close}
            onClick={() => {
              changeModalState(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default Notification;
