import { useCallback } from 'react';
import { useEvent } from 'react-use';

interface ScrollDirectionProps {
  onScrollUp?(): void;
  onScrollDown?(): void;
  threshold: number;
}

export const useScrollingDirection = (props: ScrollDirectionProps) => {
  let debounce = false,
    previousScroll = 0;

  const updateScrollDirection = useCallback(() => {
    const scrollY = window.scrollY;
    if (Math.abs(scrollY - previousScroll) >= props.threshold) {
      if (scrollY > previousScroll) {
        props.onScrollDown?.();
      } else props.onScrollUp?.();

      previousScroll = Math.max(0, scrollY);
    }

    debounce = false;
  }, []);

  const onScroll = useCallback(() => {
    if (!debounce) {
      window.requestAnimationFrame(updateScrollDirection);
      debounce = true;
    }
  }, []);

  useEvent('scroll', onScroll);
};
