import React, { useCallback, useContext, useRef, useState } from 'react';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { useFormValidate, handleSetErrorText } from 'helpers/hooks/form';
import { submitActivate } from 'services/authService';
import { useRouter } from 'next/router';
import { handleChangeCode } from 'helpers/hooks/changeCode';
import { AppContext } from 'context/app.context';
import { MODAL } from 'helpers/constants/modals';

import Button from 'components/blocks/Button';
import Modal from 'components/blocks/Modal';
import { Form, Formik } from 'formik';
import FormInput from 'components/blocks/FormInput';

import styles from 'components/blocks/Modal/ModalAuth/ModalAuth.module.scss';
import modalStyles from 'components/blocks/Modal/Modal.module.scss';
import inputStyles from 'components/blocks/FormInput/FormInput.module.scss';

const ModalAuthActivate: React.FC = () => {
  const { t } = useTranslation(['modal', 'feedback']);
  const router = useRouter();
  const { locale: currentLanguage } = router as {
    locale: string;
  };

  const { modalType, setModalType } = useContext(AppContext);

  const confirmLicenseCode = useRef(null);
  const [licenseCodeError, setLicenseCodeError] = useState();
  const [responseFieldError, setResponseFieldError] = useState('');
  const [responseError, setResponseError] = useState('');
  const [isWaiting, setWaiting] = useState(false);

  const validateActivate = useFormValidate({
    licenseCodeError: setLicenseCodeError,
  });

  const handleValidate = values => {
    if (responseFieldError) {
      setResponseFieldError('');
    }

    if (responseError) {
      setResponseError('');
    }

    return validateActivate(values);
  };

  const handleSubmitActivate = useCallback(
    async (data, actions) => {
      submitActivate(data, actions, {
        language: currentLanguage,
        successCallback: () => {
          setModalType(MODAL.auth.activateSuccess);
        },
        errorFieldCallback: data => {
          setResponseFieldError(handleSetErrorText(data, t));
        },
        beforeSubmit: () => {
          setWaiting(true);
        },
        afterSubmit: () => {
          setWaiting(false);
        },
      });
    },
    [currentLanguage]
  );

  return (
    <Modal
      isOpen={modalType === MODAL.auth.activate}
      additionalClasses={[
        modalStyles.modal__contentCommon,
        styles.modalAuth__activate,
      ]}
      isWaiting={isWaiting}
    >
      <h3
        className={cn(
          'title__secondary',
          styles.modalAuth__title,
          styles.modalAuth__titleConfirm
        )}
      >
        {t('modal.activate.title')}
      </h3>
      <Formik
        initialValues={{
          licenseCode: '',
        }}
        validate={handleValidate}
        onSubmit={handleSubmitActivate}
      >
        {({ isSubmitting, dirty, isValid, setFieldTouched }) => (
          <Form>
            <FormInput
              type='text'
              name='licenseCode'
              placeholder={t('modal.activate.codePlaceholder')}
              error={licenseCodeError}
              label={t('modal.activate.codeText')}
              addContainerClasses={[styles.modalAuth__field]}
              onInput={e => handleChangeCode(e, 16, confirmLicenseCode)}
              onBlur={() => setFieldTouched('code')}
              innerRef={confirmLicenseCode}
            />
            {responseFieldError && (
              <div
                className={cn(
                  inputStyles.formInput__errorLabel,
                  inputStyles.formInput__errorLabelStatic
                )}
              >
                {responseFieldError}
              </div>
            )}
            <Button
              placeholder={t('modal.activate.button')}
              disabled={isSubmitting || !(dirty && isValid)}
              type='submit'
              addStyles={[
                styles.modalAuth__submit,
                styles.modalAuth__activateButton,
              ]}
            />
            {responseError && (
              <div
                className={cn(
                  inputStyles.formInput__errorLabel,
                  inputStyles.formInput__errorLabelStatic
                )}
              >
                {responseError}
              </div>
            )}
          </Form>
        )}
      </Formik>
      <button className='closeButton' onClick={() => setModalType('')} />
    </Modal>
  );
};

export default ModalAuthActivate;
