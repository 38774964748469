import React, { useState } from 'react';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';
import { cookieAgreementName } from 'helpers/cookieAgreement';

import Button from 'components/blocks/Button';

import styles from './Cookie.module.scss';
import buttonStyles from 'components/blocks/Button/Button.module.scss';
import { setCookie } from 'cookies-next/lib';

const Cookie: React.FC = () => {
  const { t } = useTranslation('common');
  const [hideCookie, setHideCookie] = useState(false);

  const handleAcceptCookie = () => {
    setCookie(cookieAgreementName, 'true', { maxAge: 60 * 60 * 24 * 365 });
    setHideCookie(true);
  };

  return (
    <div
      className={cn(styles.cookie, {
        [styles.hidden]: hideCookie,
      })}
    >
      <div className={styles.cookie__inner}>
        <div className={styles.cookie__text}>
          {t('common.cookie.text')}
          <a
            className={cn('link', 'text-green')}
            href='/privacy'
            target='_blank'
          >
            {t('common.cookie.link')}
          </a>
        </div>
        <Button
          placeholder={t('common.cookie.button')}
          addStyles={[buttonStyles.button__transparent, styles.cookie__button]}
          onClick={handleAcceptCookie}
        />
      </div>
    </div>
  );
};

export default Cookie;
