export const mainPageAnchors = {
  tariffs: 'tariffs',
  main: 'main',
  media: 'media',
  partners: 'partners',
  ambassadors: 'ambassadors',
  news: 'news',
  blog: 'blog',
};

export const genericAnchors = {
  advantages: 'advantages',
  feedbackForm: 'feedback',
};
